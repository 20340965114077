import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import LogDetailsModal from "./LogsDetails";
import Axios from "../../../service/Axios";
import { FEATURE_OPTION_LISTS } from "./constant";

const LogsTable = () => {
  const limit = 10;
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureType, setFeatureType] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, transactionId, phoneNumber, startDate, endDate, featureType]);

  const fetchData = () => {
    const url = `/v1/logsList?page=${page}&limit=${limit}&feature_type=${featureType}&transaction_id=${transactionId}&phone_number=${phoneNumber}&start_date=${startDate}&end_date=${endDate}`;
    Axios.get(url)
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data.data) && data.data.length > 0) {
          setLogs(data.data);
          setTotalPages(data.pagination.totalPage);
        } else {
          setLogs([]);
          setTotalPages(1);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const openModal = (log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      
      
      <div className='bg-gray-100 p-4 md:p-8 shadow-2xl rounded-lg'>
      <h2
        className='text-2xl font-semibold mb-6 mt-2 mx-1'
        style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
        [ Ticketing Logs List ]
      </h2>

        <div className='filter-container bg-slate-300 shadow-md p-4 rounded-md mb-8'>
          <div className='flex flex-wrap items-center'>
            <div className='flex flex-col mr-6 mb-4'>
              <label htmlFor='transactionId' className='text-xs mb-1'>
                Transaction ID:
              </label>
              <input
                type='text'
                id='transactionId'
                className='px-2 py-1 border rounded-md text-sm'
                placeholder='Enter transaction ID'
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>

            <div className='flex flex-col mr-6 mb-4'>
              <label htmlFor='phoneNumber' className='text-xs mb-1'>
                Phone Number:
              </label>
              <input
                type='text'
                id='phoneNumber'
                className='px-2 py-1 border rounded-md text-sm'
                placeholder='Enter phone number'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className='flex flex-col flex-grow mr-6 mb-4'>
              <label htmlFor='featureType' className='text-xs mb-1'>
                Feature Type:
              </label>
              <select
                id='featureType'
                className='px-2 py-1 border rounded-md text-sm'
                value={featureType}
                onChange={(e) => setFeatureType(e.target.value)}>
                {/* Add a default option */}
                <option value=''>Select Feature Type</option>
                {/* TODO: will replace with dynamic data using useState since from backend still on development  */}
                {/* Map over an array of options */}
                {FEATURE_OPTION_LISTS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex flex-col mr-6 mb-4'>
              <label htmlFor='startDate' className='text-xs mb-1'>
                Start Date:
              </label>
              <input
                type='date'
                id='startDate'
                className='px-2 py-1 border rounded-md text-sm'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className='flex flex-col mr-4 mb-4'>
              <label htmlFor='endDate' className='text-xs mb-1'>
                End Date:
              </label>
              <input
                type='date'
                id='endDate'
                className='px-2 py-1 border rounded-md text-sm'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='w-full divide-y divide-gray-200'>
            <thead className='bg-slate-300'>
              <tr>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Transaction ID
                </th>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Phone Number
                </th>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Status
                </th>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Feature Type
                </th>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Updated At
                </th>
                <th className='px-2 py-4 text-xs text-center font-bold'>
                  Updated By
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-300'>
              {logs && logs.length > 0 ? (
                logs.map((log, index) => (
                  <tr
                    key={log.related_table_key + index}
                    onClick={() => openModal(log)}
                    className='cursor-pointer hover:bg-gray-50'>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.transaction_id}
                    </td>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.phone_number}
                    </td>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.status}
                    </td>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.feature_type}
                    </td>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.updated_at}
                    </td>
                    <td className='px-2 py-4 text-xs text-center'>
                      {log.updated_by}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='6' className='text-center py-4'>
                    No logs found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='flex justify-between mt-4'>
          <button
            onClick={handlePrevPage}
            disabled={page === 1}
            className='bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            <IoIosArrowBack />
          </button>
          <div className='flex'>
            {totalPages > 10 ? (
              <>
                {page <= 5 ? (
                  <>
                    {Array.from(
                      { length: Math.min(totalPages, 10) },
                      (_, index) => (
                        <button
                          key={index + 1}
                          onClick={() => handlePageChange(index + 1)}
                          className={`mx-1 px-4 py-2 ${
                            page === index + 1
                              ? "bg-slate-500 text-white"
                              : "bg-slate-300 text-gray-600"
                          } rounded-md focus:outline-none focus:ring focus:border-gray-300 text-sm`}>
                          {index + 1}
                        </button>
                      )
                    )}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className='mx-1 px-4 py-2 bg-slate-300 text-slate-600 rounded-md focus:outline-none focus:ring focus:border-gray-300 text-sm'>
                      ...
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => handlePageChange(1)}
                      className='mx-1 px-4 py-2 bg-slate-500 text-slate-700 rounded-md focus:outline-none focus:ring focus:border-gray-300 text-sm'>
                      ...
                    </button>
                    {Array.from(
                      { length: Math.min(totalPages - page + 1, 10) },
                      (_, index) => (
                        <button
                          key={index + page}
                          onClick={() => handlePageChange(index + page)}
                          className={`mx-1 px-4 py-2 ${
                            page === index + page
                              ? "bg-slate-500 text-white"
                              : "bg-slate-300 text-slate-600"
                          } rounded-md focus:outline-none focus:ring focus:border-gray-300 text-sm`}>
                          {index + page}
                        </button>
                      )
                    )}
                  </>
                )}
              </>
            ) : (
              Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`mx-1 px-4 py-2 ${
                    page === index + 1
                      ? "bg-gray-500 text-white"
                      : "bg-slate-300 text-gray-600"
                  } rounded-md focus:outline-none focus:ring focus:border-gray-300 text-sm`}>
                  {index + 1}
                </button>
              ))
            )}
          </div>
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className='bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            <IoIosArrowForward />
          </button>
        </div>
        {isModalOpen && selectedLog && (
          <LogDetailsModal log={selectedLog} closeModal={closeModal} />
        )}
      </div>
    </div>
  );
};

export default LogsTable;
