import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { TaskItem, TaskModal, DeleteConfirmationDialog } from "./TicketTask";
import TicketRules from "./TicketRules";
import TicketFilter from "./TicketFilter";
import TicketStatus from "./TicketStatus";
import Header from "../../../components/Header/Header";

library.add(faTrash);

// const apiUrl = "https://ticketing/v1/tasks";

const customModalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "70%",
    border: "none",
    boxShadow:
      "0px 2px 6px rgba(0, 0, 0, 0.16), 0px 2px 6px rgba(0, 0, 0, 0.23)",
  },
};

const TaskBoard = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      title: "Barcode tidak muncul",
      status: "todo",
      ticketName: "",
      category: "",
      description: "",
    },
    {
      id: 2,
      title: "tidak bisa login",
      status: "todo",
      ticketName: "",
      category: "",
      description: "",
    },
    {
      id: 3,
      title: "Salah Input",
      status: "in-progress",
      ticketName: "",
      category: "",
      description: "",
    },
    {
      id: 4,
      title: "tidak bisa login",
      status: "to-do-urgent",
      ticketName: "",
      category: "",
      description: "",
    },
    {
      id: 5,
      title: "tidak bisa login",
      status: "to-do-urgent",
      ticketName: "",
      category: "",
      description: "",
    },
    {
      id: 6,
      title: "tidak bisa login",
      status: "todo",
      ticketName: "",
      category: "",
      description: "",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaskDetailsOpen, setIsTaskDetailsOpen] = useState(false);
  const [draggedTask, setDraggedTask] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const [taskTitle, setTaskTitle] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const statusOptions = ["todo", "in-progress", "done", "to-do-urgent"];

  // useEffect(() => {
  //   // Fetch tasks when the component mounts
  //   fetchTasks();
  // }, []);

  // const fetchTasks = async () => {
  //   try {
  //     const response = await fetch(apiUrl);
  //     if (response.ok) {
  //       const tasksData = await response.json();
  //       setTasks(tasksData);
  //     } else {
  //       console.error("Failed to fetch tasks:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching tasks:", error);
  //   }
  // };

  const handleDragStart = (e, taskId) => {
    e.dataTransfer.setData("taskId", taskId);
    setDraggedTask(taskId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  const handleDragCancel = () => {
    setDraggedTask(null);
  };

  const handleDrop = (e, newStatus) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData("taskId");

    if (taskId) {
      setTasks((prevTasks) => {
        const updatedTasks = [...prevTasks];
        const draggedTaskIndex = updatedTasks.findIndex(
          (task) => task.id === parseInt(taskId)
        );
        const draggedTask = updatedTasks[draggedTaskIndex];

        // Remove the dragged task from its current position
        updatedTasks.splice(draggedTaskIndex, 1);

        // Find the index to insert the dragged task at its new position
        let newIndex = updatedTasks.findIndex(
          (task) => task.status === newStatus
        );

        // If the dragged task is dropped at the top or outside of existing columns, set newIndex to 0
        if (newIndex === -1 || newIndex > draggedTaskIndex) {
          newIndex = 0;
        }

        // Insert the dragged task at the new position
        updatedTasks.splice(newIndex, 0, { ...draggedTask, status: newStatus });

        return updatedTasks;
      });
      setDraggedTask(null);
    }
  };

  const handleAddTask = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  const handleModalSubmit = () => {
    if (taskTitle.trim() !== "") {
      const updatedTasks = [...tasks];
      if (selectedTask) {
        const index = updatedTasks.findIndex(
          (task) => task.id === selectedTask.id
        );
        updatedTasks[index] = {
          ...selectedTask,
          title: taskTitle,
          ticketName,
          category,
          description,
        };
      } else {
        const newTaskObj = {
          id: tasks.length + 1,
          title: taskTitle,
          status: "todo",
          ticketName,
          category,
          description,
        };
        updatedTasks.push(newTaskObj);
      }

      setTasks(updatedTasks);
      setTaskTitle("");
      setIsModalOpen(false);
      setSelectedTask(null);
    }
  };

  const handleDeleteTask = (taskId) => {
    setDeleteConfirmation(taskId);
  };

  const confirmDeleteTask = () => {
    setTasks((prevTasks) =>
      prevTasks.filter((task) => task.id !== deleteConfirmation)
    );
    setDeleteConfirmation(null);
  };

  const handleTaskDetail = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  return (
    <div className='container max-w-none mt-4'>
      {/* <TicketRules /> */}
      <TicketFilter statusOptions={statusOptions} />

      <div
        className='bg-white rounded-lg shadow-md p-6'
        style={{ maxHeight: "600px", overflowY: "auto" }}>
        <div className='overflow-x-scroll'>
          <div className='flex flex-nowrap gap-4 grow shrink'>
            <TicketStatus
              statusTitle='To-do'
              statusColor='bg-red-100'
              taskCount={2}
            />
            <TicketStatus
              statusTitle='To-do urgent'
              statusColor='bg-gray-200'
              taskCount={2}
            />
            <TicketStatus
              statusTitle='In Progress'
              statusColor='bg-blue-100'
              taskCount={12}
            />
            <TicketStatus
              statusTitle='Need Additional Info'
              statusColor='bg-yellow-100'
              taskCount={12}
            />
            <TicketStatus
              statusTitle='Done'
              statusColor='bg-green-100'
              taskCount={17}
            />
          </div>

          <div>
            <div className='flex flex-nowrap gap-4 grow shrink'>
              <div
                className={`bg-gray-50 p-4 rounded-md shadow-md max-w-[calc(33% - 10px)] flex-none ${
                  draggedTask ? "opacity-50" : ""
                }`}
                style={{ flex: "0 0 33%" }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "todo")}>
                {tasks
                  .filter((task) => task.status === "todo")
                  .map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      draggable={!draggedTask}
                      onDragStart={(e) => handleDragStart(e, task.id)}
                      // onDragCancel={handleDragCancel}
                      onDragEnd={handleDragCancel}
                      onClick={() => handleTaskDetail(task.id)}
                      onDelete={() => handleDeleteTask(task.id)}
                    />
                  ))}
                <div className='mt-16 flex items-end'>
                  <button
                    onClick={handleAddTask}
                    className='bg-zinc-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition-all duration-300'>
                    Add Ticket
                  </button>
                </div>
              </div>

              {/* "To do urgent" column */}
              <div
                className={`bg-gray-50 p-4 rounded-md shadow-md ${
                  draggedTask ? "opacity-50" : ""
                }`}
                style={{ flex: "0 0 33%" }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "to-do-urgent")}>
                {tasks
                  .filter((task) => task.status === "to-do-urgent")
                  .map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      draggable={!draggedTask}
                      onDragStart={(e) => handleDragStart(e, task.id)}
                      onClick={() => handleTaskDetail(task.id)}
                      onDelete={() => handleDeleteTask(task.id)}
                    />
                  ))}
              </div>

              {/* "In Progress" column */}
              <div
                className={`bg-gray-50 p-4 rounded-md shadow-md max-w-[calc(33% - 10px)] flex-none ${
                  draggedTask ? "opacity-50" : ""
                }`}
                style={{ flex: "0 0 33%" }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "in-progress")}>
                {tasks
                  .filter((task) => task.status === "in-progress")
                  .map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      draggable={!draggedTask}
                      onDragStart={(e) => handleDragStart(e, task.id)}
                      onClick={() => handleTaskDetail(task.id)}
                      onDelete={() => handleDeleteTask(task.id)}
                    />
                  ))}
              </div>

              {/* "Need Additional Info" column */}
              <div
                className={`bg-gray-50 p-4 rounded-md shadow-md max-w-[calc(33% - 10px)] flex-none ${
                  draggedTask ? "opacity-50" : ""
                }`}
                style={{ flex: "0 0 33%" }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "need-additional-info")}>
                {tasks
                  .filter((task) => task.status === "need-additional-info")
                  .map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      draggable={!draggedTask}
                      onDragStart={(e) => handleDragStart(e, task.id)}
                      onClick={() => handleTaskDetail(task.id)}
                      onDelete={() => handleDeleteTask(task.id)}
                    />
                  ))}
              </div>

              {/* "Done" column */}
              <div
                className={`bg-gray-50 p-4 rounded-md shadow-md max-w-[calc(33% - 10px)] flex-none ${
                  draggedTask ? "opacity-50" : ""
                }`}
                style={{ flex: "0 0 33%" }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "done")}>
                {tasks
                  .filter((task) => task.status === "done")
                  .map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      draggable={!draggedTask}
                      onDragStart={(e) => handleDragStart(e, task.id)}
                      onClick={() => handleTaskDetail(task.id)}
                      onDelete={() => handleDeleteTask(task.id)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <TaskModal
        isOpen={isModalOpen}
        customModalStyles={customModalStyles}
        selectedTask={selectedTask}
        taskTitle={taskTitle}
        ticketName={ticketName}
        category={category}
        description={description}
        onModalClose={handleModalClose}
        onModalSubmit={handleModalSubmit}
      />

      {deleteConfirmation && (
        <DeleteConfirmationDialog
          taskId={deleteConfirmation}
          onCancel={() => setDeleteConfirmation(null)}
          onConfirm={confirmDeleteTask}
        />
      )}
    </div>
  );
};

export default TaskBoard;
