import React, { useState } from "react";
import Axios from "../../../service/Axios";
import Select from "react-select";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Datepicker from "react-tailwindcss-datepicker";

const initialState = {
  selectedPackage: null,
  searchedUser: "+62",
  uid: "",
  showAlert: null,
  packageOptions: [],
  selectStartDatePT: { startDate: null },
  userPTPackages: [],
  isSubmitting:false,
};


// disable option if pt package nil
function ChangeStartDatePT() {
  const [state, setState] = useState(initialState);

  const selectDate = (selectStartDatePT) => {
    // console.log("newValue:", selectStartDatePT.startDate);
    setState({ ...state, selectStartDatePT });
  };

  const datePickerStyles = {
    border: "solid",
    borderColor: "red",
    borderRadius: "5px",
  };

  // console.log(state.selectedPackage?.value);
  // console.log(state.selectStartDatePT?.startDate);

  const handleSearch = async (e) => {
    e.preventDefault();
    setState({ ...state, showAlert: null });

    try {
      const url = `/v1/users/query/${state.searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { uid, name, UserPTPackages } = response.data;
        // console.log(uid);


        setState({
          ...state,
          uid,
          showAlert: { type: "success", message: `User Found. ${name}` },
          packageOptions: UserPTPackages.map((userPackage) => ({
            value: userPackage.ID,
            label: userPackage.packageName,
          })),
          userPTPackages: UserPTPackages,
        });
      } else {
        setState({
          ...initialState,
          showAlert: { type: "error", message: "User not found." },
        });
      }
    } catch (error) {
      setState({
        ...initialState,
        showAlert: {
          type: "error",
          message: "User does not have PT packages.",
        },
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #3182ce",
      padding: "4px",
      marginTop: "10px",
    }),
  };

  const handleChange = (selectedOption) => {
    setState({ ...state, selectedPackage: selectedOption });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state.userPTPackages || state.userPTPackages.length === 0) {
      setState({
        ...state,
        showAlert: {
          type: "error",
          message: "User does not have PT packages.",
        },
      });
      return;
    }

    setState({ ...state, isSubmitting: true });

    try {
      const response = await Axios.put(
        `/v1/update-startdate-pt/${state.uid}`,
        {
          newStartDate: state.selectStartDatePT.startDate,
          packageId: state.selectedPackage.value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setState({
          ...state,
          showAlert: {
            type: "success",
            message: "Start Date updated successfully.",
          },
          selectedPackage: null,
          searchedUser: "+62",
        });
      } else {
        setState({
          ...state,
          showAlert: {
            type: "error",
            message: "Failed to update Start Date.",
          },
        });
      }
    } catch (error) {
      setState({
        ...state,
        showAlert: {
          type: "error",
          message: "An error occurred. Please ensure that the input is correct and that the rules are followed.",
        },
      });
    }
  };

  return (
    <div className='container mx-auto p-4'>
      {state.showAlert && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className='p-6 mx-10 bg-white shadow-lg rounded-lg'>
        <h2
          className='text-2xl font-semibold mb-11 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Change Start Date PT ]
        </h2>
        <label
          htmlFor='phone'
          className='mb-2 block text-sm font-medium text-gray-500 mx-4'>
          Phone Member Example: +6285246259910
        </label>
        <div className='flex items-center mx-4'>
          <input
            type='text'
            id='searchedUser'
            value={state.searchedUser}
            onChange={(e) =>
              setState({ ...state, searchedUser: e.target.value })
            }
            className='w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            placeholder='Enter Phone Number'
          />
          <button
            onClick={handleSearch}
            disabled={!state.searchedUser}
            className='px-4 py-3 ml-4 bg-gray-600 text-white rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring focus:border-blue-500'>
            Search
          </button>
        </div>

        <form className='mt-8 mb-8 mx-4' onSubmit={handleSubmit}>
          <label
            htmlFor='selectedPackage'
            className='mb-2 mt-4 block text-sm font-medium text-gray-500'>
            Select PT Packages:
          </label>

          <div className='mb-6'>
            <Select
              key={state.userPTPackages.length}
              id='selectedPackage'
              value={state.selectedPackage}
              isDisabled={state.searchedUser === "+62"}
              onChange={handleChange}
              options={state.userPTPackages.map((userPackage) => ({
                value: userPackage.ID,
                label: userPackage.packageName,
              }))}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className='mb-6 mt-8'>
            <label
              htmlFor='selectedPackage'
              className='mb-2 mt-4 block text-sm font-medium text-gray-500'>
              Select New Start Date:
            </label>
            <Datepicker
              inputClassName='w-full h-12 p-4 border-2'
              toggleClassName='absolute bg-gray-500 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
              value={state.selectStartDatePT}
              asSingle={true}
              onChange={selectDate}
              style={{ ...datePickerStyles }}
              showShortcuts={true}
              displayFormat={"DD/MM/YYYY"}
            />
          </div>

          <button
            type='button'
            onClick={handleSubmit}
            className={`w-full py-3 bg-gray-600 text-white px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300 mt-8`}
            disabled={
              !state.selectedPackage || !state.selectStartDatePT.startDate
            }>
            {state.isSubmitting ? "Changing..." : "Change"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangeStartDatePT;
