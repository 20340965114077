import React, { useRef, useEffect } from "react";

const LogDetailsModal = ({ log, closeModal }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    // Scroll to the element when the modal opens
    contentRef.current &&
      contentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
  }, []);

  return (
    <div className='fixed inset-0 z-50 overflow-auto bg-black bg-opacity-30 flex items-center justify-center'>
      <div className='bg-white p-8 rounded-lg max-w-5xl max-h-5xl overflow-y-auto'>
        {/* Increased max width and max height */}
        <h2 className='text-lg text-center font-semibold mb-6'>Log Details</h2>
        <div ref={contentRef}>
          <table className='table-auto my-6 py-4 w-full border-collapse border border-gray-400'>
            <tbody>
              {Object.entries(log).map(([key, value]) => (
                <tr key={key} className="border-b border-gray-400">
                  <td className='font-bold text-sm pr-4 border-r border-gray-400 py-2 px-4'>{key}</td>
                  <td className='text-sm border-r border-gray-400 py-2 px-4'>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4"> {/* Adjusted to justify-end */}
          <button
            className='bg-gray-600 text-white px-4 py-2 rounded ml-auto' // Added ml-auto
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogDetailsModal;
