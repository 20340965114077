import React, { useReducer, useEffect } from "react";
import Axios from "../../../service/Axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PacmanLoader } from "react-spinners";
import { BiCalendar } from "react-icons/bi";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Define the initial state
const initialState = {
  searchedUser: "+62",
  selectedHomeClub: null,
  newExpiredDate: null,
  showAlert: null,
  locationOptions: [],
  isSubmitting: false,
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "SET_OPTIONS":
      return { ...state, locationOptions: action.options };
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: { type: action.alertType, message: action.message },
      };
    case "SET_SUBMITTING":
      return { ...state, isSubmitting: action.isSubmitting };
    default:
      return state;
  }
};

function ActivationMembershipHO() {
  //Use useReducer
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchLocationData();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid rgba(49, 130, 206, 0.5)",
      padding: "6px",
    }),
  };

  const fetchLocationData = () => {
    Axios
      .get("/v1/locations")
      .then((response) => {
        if (response.status === 200) {
          const filteredLocations = response.data.data.filter(
            (location) => location !== ""
          );
          // Step 5: Dispatch action to set location options
          dispatch({ type: "SET_OPTIONS", options: filteredLocations });
        } else {
          showAlertMessage("error", "Failed to fetch location data.");
        }
      })
      .catch(() => {
        showAlertMessage(
          "error",
          "An error occurred while fetching location data."
        );
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Dispatch action to set form fields
    dispatch({ type: "SET_FIELD", field: name, value });
    console.log(`Input value for ${name}: ${value}`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    // Dispatch action to set isSubmitting
    dispatch({ type: "SET_SUBMITTING", isSubmitting: true });

    const postData = {
      expiredDate: moment(state.newExpiredDate).format("YYYY-MM-DD"),
      promoLocation: state.selectedHomeClub.value,
    };

    Axios
      .post(`/v1/activate-ho/${state.searchedUser}`, postData)
      .then((response) => {
        if (response.status === 200) {
          showAlertMessage("success", "Activation successful!");
          dispatch({ type: "SET_SUBMITTING", isSubmitting: false });
          dispatch({ type: "SET_FIELD", field: "searchedUser", value: "+62" });
          dispatch({
            type: "SET_FIELD",
            field: "selectedHomeClub",
            value: null,
          });
          dispatch({ type: "SET_FIELD", field: "newExpiredDate", value: null });
        } else {
          showAlertMessage("error", "Activation failed. Please try again.");
        }
      })
      .catch(() => {
        showAlertMessage(
          "error",
          "An error occurred during activation. Please try again."
        );
      })
      .finally(() => {
        // Dispatch action to set isSubmitting
        dispatch({ type: "SET_SUBMITTING", isSubmitting: false });
      });

    console.log("Form data submitted:", state);
  };

  const handleDateChange = (date) => {
    // Dispatch action to set newExpiredDate
    dispatch({ type: "SET_FIELD", field: "newExpiredDate", value: date });
    console.log(`Selected date: ${date}`);
  };

  const showAlertMessage = (type, message) => {
    // Dispatch action to show alert
    dispatch({ type: "SHOW_ALERT", alertType: type, message });
  };

  return (
    <div className='container mx-auto p-4'>
      {state.showAlert && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className='bg-white rounded-lg shadow-3xl p-6 mx-10'>
        <h2
          className='text-2xl font-semibold mb-10 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Activate | Extend ] Membership HO
        </h2>
        <form onSubmit={handleSearch}>
          <div className='mb-6 mt-6 mx-4'>
            <label className='block text-sm font-medium text-gray-500 mb-2'>
              Phone:
            </label>
            <input
              type='text'
              name='searchedUser'
              value={state.searchedUser}
              onChange={handleInputChange}
              className='w-full py-3 px-4 mb-2 rounded-lg border border-gray-400 bg-white focus:outline-none focus:ring-2 focus:ring-gray-400 text-gray-800 shadow-md'
              placeholder='Enter Phone Number'
              readOnly={state.isSubmitting}
            />
          </div>
          <div className='mb-6 mt-6 mx-4'>
            <label className='block text-sm font-medium text-gray-500 mb-4'>
              Select Home Club:
            </label>
            <Select
              name='selectedHomeClub'
              value={state.selectedHomeClub}
              isDisabled={state.isSubmitting}
              onChange={(selectedOption) =>
                dispatch({
                  type: "SET_FIELD",
                  field: "selectedHomeClub",
                  value: selectedOption,
                })
              }
              options={state.locationOptions.map((location) => ({
                value: location,
                label: location,
              }))}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
          <div className='mb-8 mt-8 mx-4'>
            <label className='block text-sm font-medium text-gray-500 mb-4 mt-4'>
              Expired Date:
            </label>
            <div className='relative'>
              <DatePicker
                selected={state.newExpiredDate}
                onChange={handleDateChange}
                className='w-full py-1 px-4 rounded-lg border border-gray-400 bg-white focus:outline-none focus:ring-2 focus:ring-gray-400 text-gray-800 shadow-md'
                dateFormat='yyyy-MM-dd'
                withPortal
                showYearDropdown
                yearDropdownItemNumber={10}
                customInput={
                  <input
                    type='text'
                    name='newExpiredDate'
                    value={
                      state.newExpiredDate
                        ? moment(state.newExpiredDate).format("YYYY-MM-DD")
                        : ""
                    }
                    readOnly={state.isSubmitting}
                    className='w-full px-4 py-3 border rounded-lg text-xl focus:outline-none focus:ring focus:border-gray-700 cursor-pointer pl-10'
                  />
                }
              />
              <BiCalendar className='icon-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-xl text-gray-400 cursor-pointer' />
            </div>
          </div>
          <div className='mb-8 mt-8 mx-4'>
            <button
              type='submit'
              className={`w-full mb-8 mt-6 bg-gray-600 text-white py-3 px-4 rounded ${
                state.isSubmitting
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-600"
              }
            focus:outline-none focus:ring focus:ring-gray-300`}>
              {state.isSubmitting && (
                <div className='absolute flex items-center justify-start'>
                  <PacmanLoader type='Pacman' color='#FFD300' size={18} />
                </div>
              )}{" "}
              {state.isSubmitting ? "Activating..." : "Activate"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ActivationMembershipHO;
