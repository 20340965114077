import React, { useState, useEffect } from "react";
import Axios from "../../../service/Axios";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeEmail() {
  const [newEmailMember, setnewEmailMember] = useState("");
  const [searchedUser, setSearchedUser] = useState("+62");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [phone, setPhone] = useState("+62");
  const [userFound, setUserFound] = useState(false);

  const changeUserName = async (e) => {
    e.preventDefault();
    if (!newEmailMember) {
      setShowAlert({
        type: "error",
        message: "Please input a new name for the member",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await Axios.put(
        "/v1/change-email",
        {
          phoneNumber: phone,
          newUserEmail: newEmailMember,
        }
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Email changed successfully.",
        });
        setnewEmailMember("");
        setSearchedUser("+62");
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      setUserFound(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, email } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: email,
        });
        setUserFound(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setUserFound(false);
      setShowAlert({
        type: "error",
        message: "User not found.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  useEffect(() => {}, []);

  return (
    <div className='container mx-auto p-4'>
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className='p-6 mx-8 mt-4 bg-white shadow-lg rounded-lg'>
        <h2
          className='text-2xl font-semibold mb-8 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Change Email ]
        </h2>
        {/* Phone Member Input */}
        <div className='mb-4 mt-8'>
          <label
            htmlFor='phone'
            className='block text-sm mx-4 font-medium text-gray-400'>
            phone member example: +6285246259910
          </label>
          <div className='flex items-center mx-4 mt-2'>
            <input
              type='text'
              id='phone'
              className='w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
              placeholder='Enter phone number'
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`px-4 py-3 ml-4 bg-slate-600 text-white rounded-lg focus:outline-none focus:ring focus:border-blue-500 hover:bg-yellow-600 ${
                isLoading
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 hover:opacity-90"
              } focus:outline-none focus:ring focus:ring-blue-300`}
              disabled={isSubmitting}
              style={{
                boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                padding: "15px",
              }}>
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* New Name Member Input */}
        <label
          htmlFor='newEmailMember'
          className='block text-sm font-medium text-gray-400 mx-4 mt-6'>
          new email
        </label>
        <div className='mt-4 mb-12 mx-4'>
          <input
            type='text'
            id='newEmailMember'
            value={newEmailMember}
            onChange={(e) => setnewEmailMember(e.target.value)}
            className='w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            placeholder='Enter new email member'
          />
        </div>

        <div className='mb-8 mt-2 mx-4'>
          <button
            onClick={changeUserName}
            className={`w-full bg-slate-600 text-white py-2 px-4 rounded hover:bg-yellow-600 ${
              isLoading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100 hover:opacity-90"
            } focus:outline-none focus:ring focus:ring-blue-300`}
            disabled={!newEmailMember || isSubmitting}
            style={{
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
              padding: "13px",
            }}>
            {isSubmitting ? "Changing..." : "Change"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmail;
