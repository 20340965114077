import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import ChangeHomeClub from "./pages/ticketing/change-home-club/ChangeHomeClub";
import ChangeName from "./pages/ticketing/change-name/ChangeName";
import ChangeStartDate from "./pages/ticketing/change-start-date/ChangeStartDate";
import ChangeExpiredDate from "./pages/ticketing/change-expired-date/ChangeExpiredDate";
import TaskBoard from "./pages/dashboard/ticketing-board/TicketingBoard";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./pages/auth/LoginPage";
import ActivationMembershipHO from "./pages/reccurring/activation-membership-ho/ActivationMembershipHo";
import BackdateTransactions from "./pages/ticketing/backdate-transaction/BackdateTransaction";
import ExtendMembershipCSVPage from "./pages/reccurring/extend-membership-csv/ExtendMembershipCSV";
import ChangeEmail from "./pages/ticketing/change-email/ChangeEmail";
import ExtendMembershipUserAppIdCSVPage from "./pages/reccurring/extend-membership-appid-csv/ExtendMembershipAppIdCSV";
import DeactivationMembershipHO from "./pages/reccurring/deactivation-membership-ho/DeactivationMembershipHo";
import ChangeStartDatePT from "./pages/ticketing/change-startdate-pt/ChangeStartDatePT";
import ChangePaymentMethods from "./pages/ticketing/change-payment-method";
import ChangePromoCode from "./pages/ticketing/change-promo-code/ChangePromoCode";
import LogsTable from "./pages/dashboard/ticketing-logs/TicketingLogs";
import ChangePackages from "./pages/ticketing/change-package/ChangePackage";
import ChangeClassCost from "./pages/ticketing/change-class-cost/ChangeClassCost";
import DeleteTransaction from "./pages/ticketing/delete-transaction/DeleteTransaction";

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route
            path='/bug-tracking'
            element={
              <ProtectedRoute>
                <TaskBoard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/activation-membership-ho'
            element={
              <ProtectedRoute>
                <ActivationMembershipHO />
              </ProtectedRoute>
            }></Route>
          <Route
            path='/deactivation-membership-ho'
            element={
              <ProtectedRoute>
                <DeactivationMembershipHO />
              </ProtectedRoute>
            }></Route>
          <Route
            path='/extend-membership-csv'
            element={
              <ProtectedRoute>
                <ExtendMembershipCSVPage />
              </ProtectedRoute>
            }></Route>
          <Route
            path='/extend-membership-userappid-csv'
            element={
              <ProtectedRoute>
                <ExtendMembershipUserAppIdCSVPage />
              </ProtectedRoute>
            }></Route>
          <Route
            path='/change-home-club'
            element={
              <ProtectedRoute>
                <ChangeHomeClub />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-promo-code'
            element={
              <ProtectedRoute>
                <ChangePromoCode />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-package-plan'
            element={
              <ProtectedRoute>
                <ChangePackages />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-payment-methods'
            element={
              <ProtectedRoute>
                <ChangePaymentMethods />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-name'
            element={
              <ProtectedRoute>
                <ChangeName />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-email'
            element={
              <ProtectedRoute>
                <ChangeEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-start-date'
            element={
              <ProtectedRoute>
                <ChangeStartDate />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-start-date-pt'
            element={
              <ProtectedRoute>
                <ChangeStartDatePT />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-expired-date'
            element={
              <ProtectedRoute>
                <ChangeExpiredDate />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-class-cost'
            element={
              <ProtectedRoute>
                <ChangeClassCost />
              </ProtectedRoute>
            }
          />
          <Route
            path='/task-board'
            element={
              <ProtectedRoute>
                <TaskBoard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <LogsTable />
              </ProtectedRoute>
            }
          />
          <Route
            path='/backdate-transaction'
            element={
              <ProtectedRoute>
                <BackdateTransactions />
              </ProtectedRoute>
            }
          />
          <Route
            path='/delete-transaction'
            element={
              <ProtectedRoute>
                <DeleteTransaction />
              </ProtectedRoute>
            }
          />
          <Route path='/login' element={<LoginPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
