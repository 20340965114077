import React from "react";

const TicketStatus = ({ statusTitle, statusColor, taskCount }) => {
  return (
    <div className='flex-auto w-32' style={{ flex: "0 0 33%" }}>
      <h3 className={`text-sm font-semibold mb-6 p-2 rounded-md ${statusColor} w-auto inline-block`}>
        {statusTitle}
      </h3>
      <h3 className='text-sm mb-6 p-2 rounded-md ml-2 w-auto inline-block'>
        {taskCount}
      </h3>
    </div>
  );
};

export default TicketStatus;
