// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { role: null, email: null},
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setEmailUser: (state, action) => {
      state.email = action.payload;
    }
  },
});

export const { setRole, setEmailUser  } = userSlice.actions;
export default userSlice.reducer;
