import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PacmanLoader } from "react-spinners";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Axios from "../../../service/Axios";

function DeactivationMembershipHO() {
  const [phoneUserDeactivate, setPhoneUserDeactivate] = useState("+62");
  const [showAlert, setShowAlert] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPhoneUserDeactivate(value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    Axios
      .post(`/v1/deactivate-ho/${phoneUserDeactivate}`)
      .then((response) => {
        if (response.status === 200) {
          showAlertMessage("success", "Deactivation successful!");
        } else {
          showAlertMessage("error", "Deactivation failed. Please try again.");
        }
      })
      .catch(() => {
        showAlertMessage(
          "error",
          "An error occurred during deactivation. Please try again."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const showAlertMessage = (type, message) => {
    setShowAlert({ type, message });
  };

  return (
    <div className='container mx-auto p-4'>
      {showAlert && (
        <NotificationAlert type={showAlert.type} message={showAlert.message} />
      )}
      <div className='bg-white rounded-lg shadow-3xl p-6 mx-10'>
        <h2
          className='text-2xl font-semibold mb-10 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Deactivate Membership HO ]
        </h2>
        <form onSubmit={handleSearch}>
          <div className='mb-6 mt-6 mx-4'>
            <label className='block text-sm font-medium text-gray-500 mb-2'>
              Phone:
            </label>
            <input
              type='text'
              value={phoneUserDeactivate}
              onChange={handleInputChange}
              className='w-full py-3 px-4 mb-2 rounded-lg border border-gray-400 bg-white focus:outline-none focus:ring-2 focus:ring-gray-400 text-gray-800 shadow-md'
              placeholder='Enter Phone Number'
              readOnly={isSubmitting}
            />
          </div>
          <div className='mb-8 mt-8 mx-4'>
            <button
              type='submit'
              className={`w-full mb-8 mt-6 bg-gray-600 text-white py-3 px-4 rounded ${
                isSubmitting
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-600"
              }
            focus:outline-none focus:ring focus:ring-gray-300`}>
              {isSubmitting && (
                <div className='absolute flex items-center justify-start'>
                  <PacmanLoader type='Pacman' color='#FFD300' size={18} />
                </div>
              )}{" "}
              {isSubmitting ? "Deactivating..." : "Deactivate"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeactivationMembershipHO;
