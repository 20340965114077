import React, { useReducer } from "react";
import { PacmanLoader } from "react-spinners";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Axios from "../../../service/Axios";

// Initial state and reducer function
const initialState = {
  csvFile: null,
  logs: "",
  showAlert: null,
  isSubmitting: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: { type: action.alertType, message: action.message },
      };
    case "SET_SUBMITTING":
      return { ...state, isSubmitting: action.isSubmitting };
    default:
      return state;
  }
};

// Main component
function ExtendMembershipCSVPage() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "SET_FIELD", field: name, value });
  };

  const handleFileChange = (e) => {
    const csvFile = e.target.files[0];
    dispatch({ type: "SET_FIELD", field: "csvFile", value: csvFile });
  };

  const handleUpload = async () => {
    dispatch({ type: "SET_SUBMITTING", isSubmitting: true });

    const formData = new FormData();
    formData.append("csvFile", state.csvFile);

    try {
      const response = await Axios.post(
        `/v1/upload-csv?logs=${encodeURIComponent(
          state.logs
        )}`,
        formData
      );

      if (response.status === 200) {
        showAlertMessage(
          "success",
          "File uploaded and processed successfully!"
        );
        dispatch({ type: "SET_SUBMITTING", isSubmitting: false });
        dispatch({ type: "SET_FIELD", field: "csvFile", value: null });
        dispatch({ type: "SET_FIELD", field: "logs", value: "" });
      } else {
        showAlertMessage(
          "error",
          "File upload and processing failed. Please try again."
        );
      }
    } catch (error) {
      showAlertMessage(
        "error",
        "An error occurred during file upload and processing. Please try again."
      );
    } finally {
      dispatch({ type: "SET_SUBMITTING", isSubmitting: false });
    }
  };

  const showAlertMessage = (type, message) => {
    dispatch({ type: "SHOW_ALERT", alertType: type, message });

    setTimeout(() => {
      dispatch({ type: "SHOW_ALERT", alertType: null, message: null });
    }, 5000);
  };

  return (
    <div className='container mx-auto p-4'>
      {state.showAlert && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className='bg-white rounded-lg shadow-3xl p-6 mx-4'>
        <h2 className='text-2xl font-semibold mt-6 mb-10 mx-4 text-gray-800'>
          [ Extend Membership with input logs ]
        </h2>
        <form>
          <div className='mb-6 mt-6 mx-4'>
            <label
              className='block mb-2 mt-2 text-sm font-medium text-gray-700 dark:text-gray '
              htmlFor='file_input'>
              Upload file
            </label>
            <input
              type='file'
              name='csvFile'
              onChange={handleFileChange}
              className='block w-full p-3 text-lg text-gray-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-100 dark:text-gray-400 focus:outline-none dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-100'
              aria-describedby='file_input_help'
              id='file_input'
            />
            <p
              className='mt-1 mb-4 text-xs text-gray-400 dark:text-gray-300'
              id='file_input_help'>
              CSV file (MAX. 200KB).
            </p>
          </div>

          <div className='mb-6 mt-6 mx-4'>
            <label className='block text-sm font-medium text-gray-700 mb-2 mt-2'>
              Logs:
            </label>
            <input
              type='text'
              name='logs'
              value={state.logs}
              onChange={handleInputChange}
              className='w-full py-4 px-4 mb-2 rounded-lg border border-gray-300 focus:ring-gray-400 focus:ring-2 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              placeholder="Enter logs (e.g., 'Extend membership 1 month')"
            />
          </div>
          <div className='mb-4 mt-6 mx-4'>
            <button
              type='button'
              onClick={handleUpload}
              className={`w-full mb-8 mt-6 bg-gray-600 text-white py-3 rounded ${
                state.isSubmitting
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-600"
              }
              focus:outline-none focus:ring focus:ring-gray-300 relative`}>
              {state.isSubmitting && (
                <div className='absolute inset-0 flex items-center ml-72 justify-start'>
                  <PacmanLoader type='Pacman' color='#FFD300' size={18} />
                </div>
              )}
              {state.isSubmitting ? "Uploading..." : "Upload and Process CSV"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ExtendMembershipCSVPage;
