import React from "react";

const TicketRules = () => {
  return (
    <div className='bg-white rounded-lg shadow-md p-6 mb-6' style={{ height: "400px", overflowY: "scroll" }}>
      <h2 className='text-2xl font-semibold mb-4'>OPS Ticketing Board</h2>
      <ul className='list-disc text-sm text-gray-700 pl-4'>
        <li>
          To Do → Menunjukan ticketing yang baru saja diinput dan belum dikerjakan oleh tim IT.
        </li>
        <li>
          To-do Urgent → Ticket yang perlu didahulukan untuk dikerjakan. Status ini akan otomatis terisi sesuai dengan kategori ticket.
        </li>
        <li>
          In Progress → Ticket yang lagi dikerjakan dan/atau membutuhkan informasi tambahan dari tim lapangan akan masuk ke dalam status.
        </li>
        <li>
          Up Next → Ticketing yang telah ditinjau dan diprioritisasi untuk pengerjaanya.
        </li>
        <li>
          Need Approval/Check → Ticket pada status ini menandakan perlu adanya diskusi internal dari tim HO sebelum ticket tersebut dapat dikerjakan.
        </li>
        <li>
          Need Additional Info → Ticket yang membutuhkan informasi tambahan sebelum bisa dikerjakan oleh tim IT.
        </li>
        <li>Done → Ticket yang sudah selesai dikerjakan</li>
      </ul>
      <p className='text-sm text-gray-700 mt-4'>*NB:</p>
      <ul className='list-disc text-sm text-gray-700 pl-4'>
        <li>
          Pergantian status dari tiket hanya dapat dilakukan oleh tim HO.
        </li>
        <li>
          Dilarang menghapus ticket orang lain. Ticket yang dimasukan sendiri dapat di delete apabila finan permasalahan sudah terselesaikan tanpa bantuan dari IT.
        </li>
        <li>
          Apabila ada tiket baru dengan permasalahan yang sama namun sudah pernah diselesaikan tiketnya, maka wajib untuk membuat tiket baru.
        </li>
      </ul>
    </div>
  );
}

export default TicketRules;
