import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TaskItem Component
const TaskItem = ({
  task,
  draggable,
  onDragStart,
  onDragEnd,
  onClick,
  onDelete,
}) => {
  const taskItemClasses = `task-item bg-white p-6 rounded-md cursor-pointer hover:bg-gray-100 transition-transform duration-200 shadow-md w-full ${
    draggable ? "transform hover:scale-105" : ""
  }`;
  const ref = useRef(null);

  useEffect(() => {
    const fn = () => {
      onDragEnd?.();
    };

    if (ref.current) {
      ref.current.addEventListener("dragend", fn);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("dragend", fn);
      }
    };
  }, [onDragEnd]);

  return (
    <div ref={ref} className='mb-6 mt-4 flex justify-between items-center'>
      <div
        className={taskItemClasses}
        draggable={draggable}
        onDragStart={onDragStart}
        onClick={onClick}>
        <div className='flex items-center justify-between'>
          <p className='text-sm font-semibold'>{task.title}</p>
          <button
            onClick={onDelete}
            className='text-gray-600 hover:text-red-800'>
            <FontAwesomeIcon icon='trash' />
          </button>
        </div>
      </div>
    </div>
  );
};

// TaskModal Component
const TaskModal = ({
  isOpen,
  customModalStyles,
  selectedTask,
  taskTitle,
  setTaskTitle,
  category,
  setCategory,
  description,
  setDescription,
  attachment,
  setAttachment,
  onModalClose,
  onModalSubmit,
  status,
  setStatus,
}) => {
  // Dummy data for dropdowns
  const ticketTitles = ["Change Home Club", "Change Name", "Change Email"];
  const statuses = ["To Do", "In Progress", "Done"];
  const ticketCategories = ["Bug", "Finance", "Change Data Member"];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onModalClose}
      style={customModalStyles}
      contentLabel='Task Details Modal'>
      <h2 className='text-2xl font-semibold mb-6'>
        {selectedTask ? "Edit Task" : "Task Details"}
      </h2>
      <form>
        {[
          {
            label: "Ticket Title",
            id: "taskTitle",
            value: taskTitle,
            onChange: (selected) => setTaskTitle(selected[0]?.value),
            options: ticketTitles,
          },
          {
            label: "Status",
            id: "status",
            value: status,
            onChange: (selected) => setStatus(selected[0]?.value),
            options: statuses,
          },
          {
            label: "Ticket Category",
            id: "category",
            value: category,
            onChange: (selected) => setCategory(selected[0]?.value),
            options: ticketCategories,
          },
          {
            label: "Attachment",
            id: "attachment",
            value: attachment,
            onChange: (e) => setAttachment(e.target.files[0]),
            type: "file",
          },
          {
            label: "Description",
            id: "description",
            value: description,
            onChange: (e) => setDescription(e.target.value),
            textarea: true,
          },
        ].map(({ label, id, value, onChange, textarea, options, type }) => (
          <div key={id} className='mb-4'>
            <label
              htmlFor={id}
              className='block text-sm font-medium text-gray-700'>
              {label}
            </label>
            {textarea ? (
              <textarea
                id={id}
                value={value}
                onChange={onChange}
                className='w-full px-3 py-2 border border-gray-400 rounded-md resize-none'
                rows={4}
              />
            ) : type === "file" ? (
              <input
                type='file'
                id={id}
                onChange={onChange}
                className='w-full px-3 py-2 border border-gray-400 rounded-md'
              />
            ) : (
              <Select
                id={id}
                values={[{ value, label: value }]}
                options={options.map((option) => ({
                  value: option,
                  label: option,
                }))}
                onChange={onChange}
                multi={false}
              />
            )}
          </div>
        ))}
        <div className='flex justify-end'>
          <button
            onClick={onModalSubmit}
            className='bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-yellow-600'>
            {selectedTask ? "Save" : "Add Ticket"}
          </button>
          <button
            onClick={onModalClose}
            className='ml-2 text-gray-600 hover:text-gray-800'>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

// DeleteConfirmationDialog Component
const DeleteConfirmationDialog = ({ onCancel, onConfirm }) => {
  return (
    <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
      <div className='bg-white p-4 rounded-md shadow-md'>
        <p className='text-lg font-semibold'>
          Are you sure you want to delete this Ticket?
        </p>
        <div className='mt-4 flex justify-end'>
          <button
            onClick={onCancel}
            className='text-gray-600 hover:text-gray-800 mr-4'>
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className='text-red-600 hover:text-red-800'>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export { TaskItem, TaskModal, DeleteConfirmationDialog };
