import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";

function ChangePaymentMethods() {
  const [type, setType] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactionID, setTransactionID] = useState("");
  const [paymentMethod1, setPaymentMethod1] = useState(null);
  const [paymentAmount1, setPaymentAmount1] = useState("");
  const [paymentID1, setPaymentID1] = useState("");
  const [paymentMethod2, setPaymentMethod2] = useState(null);
  const [paymentAmount2, setPaymentAmount2] = useState("");
  const [paymentID2, setPaymentID2] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #26344a",
      padding: "4px",
      marginTop: "10px",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#289ec9" : isFocused ? "#cce5ff" : "white", // Background color when option is selected or focused
      color: isSelected ? "white" : "black", // Text color
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await Axios.put("/v1/change-payment-method", {
        typeChange: type?.value,
        transactionId: transactionID,
        pmID1: JSON.stringify(paymentMethod1?.value),
        paymentMethod1: paymentMethod1?.label || "",
        paymentAmount1,
        paymentID1,
        pmID2: JSON.stringify(paymentMethod2?.value),
        paymentMethod2: paymentMethod2?.label || "",
        paymentAmount2,
        paymentID2,
      });

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Payment Methods updated successfully.",
        });
        setType(null);
        setTransactionID("");
        setPaymentMethod1(null);
        setPaymentAmount1("");
        setPaymentID1("");
        setPaymentMethod2(null);
        setPaymentAmount2("");
        setPaymentID2("");
      } else {
        setShowAlert({
          type: "error",
          message: "Failed to update Payment Methods.",
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = "No response received from server.";
      } else {
        errorMessage = "Request setup error.";
      }

      setShowAlert({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {}, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await Axios.get("/v1/payment-method-list");
        setPaymentMethods(response.data);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const renderPaymentOptions = () => {
    return paymentMethods
      .filter((paymentMethod) => paymentMethod)
      .map((paymentMethod) => ({
        value: paymentMethod.payment_id,
        label: paymentMethod.payment_name,
      }));
  };

  const renderFormFields = () => {
    switch (type?.value) {
      case "change_payment_method":
        return (
          <>
            <div className='mt-8 mb-8 mx-4'>
              <label
                htmlFor='transactionID'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Transaction ID:
              </label>
              <div className='flex items-center'>
                <input
                  type='text'
                  id='transactionID'
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                  className='py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Transaction ID'
                />
              </div>
            </div>
            <div className='mt-8 mb-8 mx-4'>
              <label
                htmlFor='paymentMethod1'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Payment Method 1:
              </label>
              <div className='mb-6'>
                <Select
                  id='paymentMethod1'
                  value={paymentMethod1}
                  onChange={(selectedOption) =>
                    setPaymentMethod1(selectedOption)
                  }
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <label
                htmlFor='paymentID1'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Payment ID 1:
              </label>
              <input
                type='text'
                id='paymentID1'
                value={paymentID1}
                onChange={(e) => setPaymentID1(e.target.value)}
                className='py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                placeholder='Enter Payment ID 1'
              />
            </div>
          </>
        );
      case "change_payment_method_split":
        return (
          <>
            <div className='mt-8 mb-8 mx-4'>
              <label
                htmlFor='transactionID'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Transaction ID:
              </label>
              <div className='flex items-center'>
                <input
                  type='text'
                  id='transactionID'
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                  className='py-2 px-4 border-2 border-slate-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Transaction ID'
                />
              </div>
            </div>
            <div className='mt-8 mb-8 mx-4'>
              <label
                htmlFor='paymentMethod1'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Payment Method 1:
              </label>
              <div className='mb-6'>
                <Select
                  id='paymentMethod1'
                  value={paymentMethod1}
                  onChange={(selectedOption) =>
                    setPaymentMethod1(selectedOption)
                  }
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <input
                type='text'
                id='paymentAmount1'
                value={paymentAmount1}
                onChange={(e) => setPaymentAmount1(e.target.value)}
                className='py-2 px-4 mr-6 mb-2 border-2 border-slate-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                placeholder='payment amount 1'
              />
              <input
                type='text'
                id='paymentID1'
                value={paymentID1}
                onChange={(e) => setPaymentID1(e.target.value)}
                className='py-2 px-4 border-2 border-slate-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                placeholder='payment ID 1'
              />
            </div>
            <div className='mt-8 mb-8 mx-4'>
              <label
                htmlFor='paymentMethod2'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Payment Method 2:
              </label>
              <div className='mb-6'>
                <Select
                  id='paymentMethod2'
                  value={paymentMethod2}
                  onChange={(selectedOption) =>
                    setPaymentMethod2(selectedOption)
                  }
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <input
                type='text'
                id='paymentAmount2'
                value={paymentAmount2}
                onChange={(e) => setPaymentAmount2(e.target.value)}
                className='py-2 px-4 mb-2 mr-6 border-2 border-slate-300 rounded-lg focus:outline-none focus:ring focus:border-blue-800'
                placeholder='payment amount 2'
              />
              <input
                type='text'
                id='paymentID2'
                value={paymentID2}
                onChange={(e) => setPaymentID2(e.target.value)}
                className='py-2 px-4 border-2 border-slate-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300'
                placeholder='payment ID 2'
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='container scale-90 mx-auto'>
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className='p-6 mx-10 bg-white shadow-lg rounded-lg'>
        <h2
          className='text-2xl font-semibold mb-8 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Change Payment Methods ]
        </h2>

        <div className='mt-8 mb-8 mx-4'>
          <label
            htmlFor='type'
            className='mb-2 block text-sm font-medium text-gray-500'>
            Type:
          </label>
          <div className='mb-6'>
            <Select
              id='type'
              value={type}
              onChange={(selectedOption) => setType(selectedOption)}
              options={[
                {
                  value: "change_payment_method",
                  label: "Change Payment Method",
                },
                {
                  value: "change_payment_method_split",
                  label: "Change Payment Method Split",
                },
              ]}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className='mb-8 mt-2 mx-4'>
          {transactionID.trim() ? (
            <button
              onClick={handleSubmit}
              className={`w-full bg-gray-600 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300`}
              disabled={isSubmitting}
              style={{
                boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                padding: "10px",
              }}>
              {isSubmitting ? "Updating..." : "Update"}
            </button>
          ) : (
            <button
              className={`w-full bg-gray-400 text-white py-2 px-4 rounded cursor-not-allowed focus:outline-none focus:ring focus:ring-blue-300`}
              disabled={true}
              style={{
                boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                padding: "10px",
              }}>
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangePaymentMethods;
