import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";

const Header = () => {
  const [ticketsEnteredToday, setTicketsEnteredToday] = useState(0);
  const [ticketsDoneToday, setTicketsDoneToday] = useState(0);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      const newTicketsEntered = Math.floor(Math.random() * 5);
      const newTicketsDone = Math.floor(Math.random() * 3);

      setTicketsEnteredToday((prev) => prev + newTicketsEntered);
      setTicketsDoneToday((prev) => prev + newTicketsDone);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const doneTodayBorderClass =
    ticketsDoneToday > 0 ? "border-2 border-green-500" : "";
  const enteredTodayBorderClass =
    ticketsEnteredToday > 0 ? "border-2 border-yellow-500" : "";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Check if the header content should be shown based on environment variable
  const showHeaderContent = process.env.REACT_APP_FF_TICKETING_COUNT === "true";

  // Define the container style based on whether showHeaderContent is true
  const containerStyle = {
    zoom: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
        backgroundImage: isDarkMode
      ? "linear-gradient(to top, #dfe9f3 5%, white 100%)" // Dark mode background gradient
      : "linear-gradient(90deg, #eeeeee, #f0f0f0)", // Light mode background gradient
  };

  return (
    <div className='header-container mx-4' style={containerStyle}>
      <div className='ticket-count-container'>
        {showHeaderContent && (
          <div className='header-content'>
            <div className='stats-section'>
              <div
                className={`stats-box ${doneTodayBorderClass}`}
                style={{ marginRight: "10px" }}>
                <div className='stats'>
                  <span className='stats-value text-xs'>
                    {ticketsDoneToday}
                  </span>
                  <span className='stats-label text-xs'>Completed</span>
                  <span className='stats-sublabel text-xs'>Ticket Today</span>
                </div>
              </div>
              <div className={`stats-box ${enteredTodayBorderClass}`}>
                <div className='stats'>
                  <span className='stats-value text-xs'>
                    {ticketsEnteredToday}
                  </span>
                  <span className='stats-label text-xs'>New Ticket</span>
                  <span className='stats-sublabel text-xs'>Entered Today</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='profile-section flex {}'>
        <FontAwesomeIcon icon={faUserCircle} className='profile-icon' />
        <div className='profile-info flex'>
          <p className='header-title mr-10'>
            {localStorage.getItem("email")}
          </p>
          <div className='logout-section ml-0' style={{ marginLeft: "0" }}>
            <FontAwesomeIcon icon={faSignOutAlt} className='logout-icon mr-1' />
            <p className='user-logout' onClick={handleLogout}>
              Log Out
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
