import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";

const initialState = {
  type: null,
  transactionID: "",
  promoCode: "",
  isSubmitting: false,
  showAlert: { type: "", message: "" },
};

function ChangePromoCode() {
  const [state, setState] = useState(initialState);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #3182ce",
      padding: "4px",
      marginTop: "10px",
    }),
  };

  const handleInputChange = (fieldName, value) => {
    setState((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const clearAlert = () => {
    setState((prevState) => ({
      ...prevState,
      showAlert: { type: "", message: "" },
    }));
  };

  useEffect(() => {
    if (state.showAlert.message) {
      const timer = setTimeout(() => {
        clearAlert();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [state.showAlert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const endpoint = "/v1/change-promocode";

    try {
      const apiResponse = await Axios.put(endpoint, {
        typeChange: state.type?.value,
        transactionId: state.transactionID,
        promoCode: state.promoCode,
      });

      if (apiResponse && apiResponse.status === 200) {
        setState(initialState);
        setState((prevState) => ({
          ...prevState,
          showAlert: {
            type: "success",
            message: "Data updated successfully.",
          },
        }));
      } else {
        const errorMessage = apiResponse?.data?.error || "Failed to update data.";
        setState((prevState) => ({
          ...prevState,
          showAlert: {
            type: "error",
            message: errorMessage,
          },
        }));
      }
    } catch (error) {

      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = "No response received from server.";
      } else {
        errorMessage = "Request setup error.";
      }

      setState((prevState) => ({
        ...prevState,
        showAlert: {
          type: "error",
          message: errorMessage,
        },
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  const renderFormFields = () => {
    const { type, transactionID, promoCode } = state;
    const commonProps = {
      className: "py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500",
      onChange: (e) => handleInputChange(e.target.id, e.target.value),
    };

    switch (type?.value) {
      case "Add/Replace Promo Code":
        return (
          <div className="flex justify-center flex-col mb-16">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-4">
              <div className="flex flex-col justify-between mt-6">
                <label htmlFor="transactionID" className="mb-2 mx-2 block text-sm font-medium text-gray-500">
                  Transaction ID:
                </label>
                <input id="transactionID" value={transactionID} {...commonProps} placeholder="Enter Transaction ID" />
              </div>
              <div className="flex flex-col justify-between mt-6">
                <label htmlFor="promoCode" className="mb-2 mx-2 block text-sm font-medium text-gray-500">
                  Promo Code:
                </label>
                <input id="promoCode" value={promoCode} {...commonProps} placeholder="Enter Promo Code" />
              </div>
            </div>
          </div>
        );
      case "Take Out Promo Code":
        return (
          <div className="mt-8 mb-8 mx-4">
            <label htmlFor="transactionID" className="mb-2 block text-sm font-medium text-gray-500">
              Transaction ID:
            </label>
            <input id="transactionID" value={transactionID} {...commonProps} placeholder="Enter Transaction ID" />
          </div>
        );
      default:
        return null;
    }
  };

  const { showAlert, type, transactionID, isSubmitting } = state;

  return (
    <div className="container mx-auto p-4">
      {showAlert.message && <NotificationAlert type={showAlert.type} message={showAlert.message} />}
      <div className="p-6 mx-10 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold mb-8 mt-6 mx-4">[ Change Promo Codes ]</h2>
        <div className="mt-8 mb-8 mx-4">
          <label htmlFor="type" className="mb-2 block text-sm font-medium text-gray-500">
            Type:
          </label>
          <div className="mb-6">
            <Select
              id="type"
              value={type}
              onChange={(selectedOption) => handleInputChange("type", selectedOption)}
              options={[
                { value: "Take Out Promo Code", label: "Take Out Promo Code" },
                { value: "Add/Replace Promo Code", label: "Add/Replace Promo Code" },
              ]}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>
        {renderFormFields()}
        <div className="mb-8 mt-2 mx-4">
          <button
            onClick={handleSubmit}
            className={`w-full bg-gray-600 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300 ${
              !type || !transactionID || isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!type || !transactionID || isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePromoCode;
