import React from "react";

const TicketFilter = ({ statusOptions }) => {
  const handleApplyFilter = () => {
    // Implement your filter logic here
    console.log("Filters Applied!");
  };

  return (
    <div className='bg-white rounded-lg shadow-md p-6 mb-6'>
      <div className='flex flex-row flex-wrap mx-1'>
        <div className='flex grow flex-col mx-4'>
          {/* Filter Dropdown */}
          <label htmlFor="filter1" className='text-sm font-medium text-gray-600 mx-1 mb-1'>
            Filter 1:
          </label>
          <select id="filter1" className='px-4 p-2 rounded-full border shadow-md'>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className='flex grow flex-col mx-2'>
          {/* Filter Dropdown */}
          <label htmlFor="filter2" className='text-sm font-medium text-gray-600 mx-1 mb-1'>
            Filter 2:
          </label>
          <select id="filter2" className='px-4 p-2 rounded-full border shadow-md'>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className='flex grow flex-col mx-2'>
          {/* Filter Dropdown */}
          <label htmlFor="filter3" className='text-sm font-medium text-gray-600 mx-1 mb-1'>
            Filter 3:
          </label>
          <select id="filter3" className='px-4 p-2 rounded-full border shadow-md'>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className='flex grow flex-col mx-2'>
          {/* Filter Dropdown */}
          <label htmlFor="filter4" className='text-sm font-medium text-gray-600 mx-1 mb-1'>
            Filter 4:
          </label>
          <select id="filter4" className='px-4 p-2 rounded-full border shadow-md'>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col mx-2 mt-2 justify-center'>
          {/* Apply Filter Button */}
          <button
            onClick={handleApplyFilter}
            className='bg-zinc-500 backgrou text-white px-4 py-1.5 rounded-md hover:bg-yellow-600 transition-all duration-300 mt-2'>
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketFilter;
