import React, { useState, useEffect } from "react";
import Axios from "../../../service/Axios";
import Select from "react-select";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeHomeClub() {
  const [selectedHomeClub, setSelectedHomeClub] = useState(null);
  const [searchedUser, setSearchedUser] = useState("+62");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setShowAlert(null);

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, promoLocation, name } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: `User Found. ${name} && Current Home Club on : ${promoLocation}`,
        });
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "User not found.",
      });
    }
  };

  useEffect(() => {
    Axios
      .get("/v1/locations")
      .then((response) => {
        if (response.status === 200) {
          const filteredLocations = response.data.data.filter(
            (location) => location !== ""
          );
          setLocationOptions(filteredLocations);
        } else {
          setShowAlert({
            type: "error",
            message: "Failed to fetch location data.",
          });
        }
      })
      .catch((error) => {
        setShowAlert({
          type: "error",
          message: "An error occurred while fetching location data.",
        });
      });
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #3182ce",
      padding: "4px",
      marginTop: "10px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedHomeClub(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.put(
        `/v1/changehomeclub/${phone}`,
        {
          promoLocation: selectedHomeClub.value,
        },
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Home Club updated successfully.",
        });
        setSelectedHomeClub(null);
        setSearchedUser("+62");
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "Phone Number is Required",
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      {showAlert && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
        />
      )}
      <div className="p-6 mx-10 bg-white shadow-lg rounded-lg">
        <h2
          className="text-2xl font-semibold mb-11 mt-6 mx-4"
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}
        >
          [ Change Home Club ]
        </h2>
        <label
          htmlFor="phone"
          className="mb-2 block text-sm font-medium text-gray-500 mx-4"
        >
          Phone Member Example : +6285246259910
        </label>
        <div className="flex items-center mx-4">
          <input
            type="text"
            id="searchedUser"
            value={searchedUser}
            onChange={(e) => setSearchedUser(e.target.value)}
            className="w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            placeholder="Enter Phone Number"
          />
          <button
            onClick={handleSearch}
            disabled={!searchedUser}
            className="px-4 py-3 ml-4 bg-gray-600 text-white rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring focus:border-blue-500"
          >
            Search
          </button>
        </div>

        <form className="mt-8 mb-8 mx-4" onSubmit={handleSubmit}>
          <label
            htmlFor="selectedHomeClub"
            className="mb-2 mt-4 block text-sm font-medium text-gray-500"
          >
            Select New Home Club:
          </label>

          <div className="mb-6">
            <Select
              id="selectedHomeClub"
              value={selectedHomeClub}
              isDisabled={searchedUser === ""}
              onChange={handleChange}
              options={locationOptions.map((location) => ({
                value: location,
                label: location,
              }))}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-gray-600 text-white px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300"
            style={{ marginTop: "30px" }}
          >
            Change
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangeHomeClub;
