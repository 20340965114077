import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Axios from "../../../service/Axios";
import Select from "react-select";
import moment from "moment";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeClassCost() {
  const initialState = {
    selectedLocation: null,
    selectedInstructor: null,
    locationOptions: [],
    instructorOptions: [],
    showAlert: null,
    isLocked: false,
    dateSchedule: null,
    timeSchedule: null,
    classCost: "",
    isSubmitting: false,
    fetchInstructorListInProgress: false,
    fetchInstructorListError: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await Axios.get("/v1/locations-dydb");
        if (response.status === 200) {
          const filteredLocations = response.data.data.filter(
            (location) =>
              location !== null &&
              location !== undefined &&
              location !== "" &&
              location.Domicile !== undefined
          );
          setState((prevState) => ({
            ...prevState,
            locationOptions: filteredLocations.map((location) => ({
              value: location.Name,
              label: location.Name,
              domicile: location.Domicile ? location.Domicile : "",
            })),
          }));
        } else {
          showAlertMessage("error", "Failed to fetch location data.");
        }
      } catch (error) {
        showAlertMessage(
          "error",
          "An error occurred while fetching location data."
        );
      }
    };

    fetchLocations();
  }, []); // Include fetchLocations in the dependency array

  const fetchInstructorList = async (domicile) => {
    setState((prevState) => ({
      ...prevState,
      fetchInstructorListInProgress: true,
      fetchInstructorListError: false,
    }));

    try {
      const response = await Axios.get(
        `/v1/instructor-list/?domicile=${encodeURIComponent(domicile)}`
      );
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          instructorOptions: response.data.data.map((instructor) => ({
            value: instructor.email,
            label: instructor.instructor,
          })),
          fetchInstructorListInProgress: false,
        }));
      } else {
        showAlertMessage("error", "Failed to fetch instructor data.");
      }
    } catch (error) {
      showAlertMessage(
        "error",
        "An error occurred while fetching instructor data."
      );
      setState((prevState) => ({
        ...prevState,
        fetchInstructorListInProgress: false,
        fetchInstructorListError: true,
      }));
    }
  };

  const handleChangeLocation = async (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedLocation: selectedOption,
      selectedInstructor: null,
      instructorOptions: [],
    }));

    if (selectedOption) {
      const location = state.locationOptions.find(
        (loc) => loc.value === selectedOption.value
      ); // Find the selected location from locationOptions
      const domicile = location ? location.domicile : "";
      await fetchInstructorList(domicile);
    }
  };

  const handleChangeInstructor = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedInstructor: {
        value: selectedOption.label,
        label: selectedOption.label,
      },
    }));
  };

  const showAlertMessage = (type, message) => {
    setState((prevState) => ({
      ...prevState,
      showAlert: { type, message },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      selectedLocation,
      selectedInstructor,
      dateSchedule,
      timeSchedule,
      classCost,
    } = state;

    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    try {
      const response = await Axios.put(`/v1/change-class-cost`, {
        location_schedule: selectedLocation.value,
        instructor: selectedInstructor.value,
        date_schedule: moment(dateSchedule).format("YYYYMMDD"),
        time_schedule: timeSchedule,
        class_cost: classCost,
      });

      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          isSubmitting: false,
          selectedInstructor: null,
          selectedLocation: null,
          dateSchedule: null,
          timeSchedule: null,
          classCost: "",
        }));
        showAlertMessage("success", "Class Cost updated successfully.");
      } else {
        showAlertMessage("error", "Failed to update data.");
      }
    } catch (error) {
      showAlertMessage(
        "error",

        "An error occurred. Please ensure that the input is correct and that the rules are followed."
      );
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  return (
    <div className='container mx-auto p-4 scale-95'>
      {state.showAlert && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className='p-6 mx-10 scale-95 bg-white shadow-lg rounded-lg'>
        <h2 className='text-2xl font-semibold mb-11 mt-6 mx-4'>
          [ Change Class Cost ]
        </h2>
        <label
          htmlFor='selectedLocation'
          className='mb-2 block text-sm font-medium text-gray-500 mx-4'>
          Select Location Class:
        </label>

        <form className='mt-2 mb-8 mx-4' onSubmit={handleSubmit}>
          <div className='mb-6'>
            <Select
              id='selectedLocation'
              value={state.selectedLocation}
              isDisabled={state.dateSchedule === ""}
              onChange={handleChangeLocation}
              options={state.locationOptions.map((location) => ({
                value: location.value,
                label: location.label,
              }))}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "1px solid #3182ce",
                  padding: "4px",
                  marginTop: "10px",
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
            {state.fetchInstructorListError && (
              <div className='text-red-600 mt-2'>
                Failed to fetch instructor data.
              </div>
            )}
          </div>
          <div className='mb-6'>
            <label
              htmlFor='selectedInstructor'
              className='mb-2 block text-sm font-medium text-gray-500'>
              Select Instructor:
            </label>
            <Select
              id='selectedInstructor'
              value={state.selectedInstructor}
              isDisabled={
                state.fetchInstructorListInProgress ||
                state.fetchInstructorListError
              }
              onChange={handleChangeInstructor}
              options={state.instructorOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "1px solid #3182ce",
                  padding: "4px",
                  marginTop: "10px",
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
          </div>
          <label
            htmlFor='dateSchedule'
            className='mb-2 mt-4 block text-sm font-medium text-gray-500'>
            Date Schedule:
          </label>
          <div className='flex items-center'>
            <DatePicker
              selected={state.dateSchedule}
              onChange={(date) =>
                setState((prevState) => ({ ...prevState, dateSchedule: date }))
              }
              className='w-full border rounded-lg py-2 px-4 text-lg'
              dateFormat='yyyy-MM-dd'
              withPortal
              showYearDropdown
              yearDropdownItemNumber={10}
              customInput={
                <button
                  type='button'
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isLocked: true }))
                  }
                  disabled={state.isLocked}
                  className='relative w-full py-3 px-4 border rounded-lg text-sm focus:outline-none focus:ring focus:border-blue-500 cursor-pointer'>
                  {state.dateSchedule
                    ? moment(state.dateSchedule).format("YYYY-MM-DD")
                    : "Select Date"}
                </button>
              }
            />
          </div>

          <label
            htmlFor='timeSchedule'
            className='mb-2 mt-4 block text-sm font-medium text-gray-500'>
            Time Schedule:
          </label>
          <div className='flex items-center'>
            <input
              type='time'
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  timeSchedule: e.target.value,
                }))
              }
              value={state.timeSchedule}
              className='py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
            />
          </div>

          <label
            htmlFor='classCost'
            className='mb-2 mt-4 block text-sm font-medium text-gray-500'>
            New Class Cost
          </label>
          <div className='flex items-center'>
            <input
              type='text'
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  classCost: e.target.value,
                }))
              }
              value={state.classCost}
              className='w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
              placeholder='Enter Class Cost'
            />
          </div>
          <button
            type='submit'
            className={`w-full py-3 bg-gray-600 text-white px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300 mt-8`}
            disabled={
              !state.selectedLocation ||
              !state.dateSchedule ||
              !state.classCost
            }>
            {state.isSubmitting ? "Changing..." : "Change"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangeClassCost;
