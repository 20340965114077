import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";
import "../change-expired-date/DatePickerCustomStyles.css";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import Axios from "../../../service/Axios";

const ChangeExpiredDate = () => {
  const [searchedUser, setSearchedUser] = useState("+62");
  const [phone, setPhone] = useState("+62");
  const [existingExpires, setExpires] = useState("");
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [newExpiredDate, setNewExpiredDate] = useState("");
  const [newLogs, setNewLogs] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userFound, setUserFound] = useState(false);

  const handleSearch = async () => {
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, name, expiredMembership } = response.data;
        setPhone(phone);
        setExpires(moment(expiredMembership).toDate());
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: `${name} [ Membership expires on: ${moment(
            expiredMembership
          ).format("YYYY-MM-DD")} ]`,
        });
        setIsLocked(!isLocked);
        setUserFound(true);
      } else {
        setShowAlert({ type: "error", message: "User not found." });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({ type: "error", message: "User not found." });
      setUserFound(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  const handleExpiredDateChange = async (e) => {
    e.preventDefault();
    if (!newExpiredDate) {
      setShowAlert({
        type: "error",
        message: "Please select a new expiration date.",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      if (userFound) {
        const formattedDate = moment(newExpiredDate).format("YYYY-MM-DD");

        const response = await Axios.put(
          `/v1/update-expiration/${phone}`,
          {
            newExpirationDate: formattedDate,
            newLogs: newLogs,
          }
        );

        if (response.status === 200) {
          setShowAlert({
            type: "success",
            message: "Expired Date updated successfully.",
          });
          setNewExpiredDate("");
          setNewLogs("");
          setSearchedUser("+62");
          setIsLocked(false);
        } else {
          setShowAlert({ type: "error", message: "Something went wrong." });
        }
      } else {
        setShowAlert({ type: "error", message: "User not found." });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      setUserFound(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert.message]);

  return (
    <div className="container mx-auto p-4">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="p-6 mx-10 bg-white shadow-lg rounded-lg">
        <h2
          className="text-2xl font-semibold mb-11 mt-6 mx-4"
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}
        >
          [ Change Expired Date ]
        </h2>

        <div className="mb-4 mx-4 mt-8">
          <label htmlFor="searchedUser" className="block text-s text-gray-500">
            Phone Number:
          </label>
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Enter phone number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              className="w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className="px-4 py-3 ml-4 bg-gray-600 text-white rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring focus:border-blue-500"
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>
        <form onSubmit={handleExpiredDateChange}>
          <div className="mb-4 mx-4">
            <label
              htmlFor="newExpirationDate"
              className="block text-s text-gray-500"
            >
              New Expiration Date:
            </label>
            <div className="flex items-center">
              <DatePicker
                selected={existingExpires}
                onChange={(date) => setNewExpiredDate(date)}
                className="w-full border rounded-lg py-2 px-4 text-lg"
                dateFormat="yyyy-MM-dd"
                withPortal
                showYearDropdown
                yearDropdownItemNumber={10}
                customInput={
                  <button
                    type="button"
                    onClick={() => setShowDatePicker(true)}
                    disabled={isLocked}
                    className="w-full py-3 px-4 border rounded-lg text-lg focus:outline-none focus:ring focus:border-blue-500 cursor-pointer"
                  >
                    {newExpiredDate
                      ? moment(newExpiredDate).format("YYYY-MM-DD")
                      : "Select Date"}
                  </button>
                }
              />
              <BiCalendar
                className="icon-calendar ml-2 text-xl text-gray-400 cursor-pointer"
                onClick={() => setShowDatePicker(true)}
              />
            </div>
          </div>
          <div className="mb-4 mx-4">
            <label htmlFor="newLogs" className="block text-s text-gray-500">
              Note:
            </label>
            <input
              type="text"
              id="newLogs"
              value={newLogs}
              onChange={(e) => setNewLogs(e.target.value)}
              className="w-full mb-6 py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              disabled={isSubmitting}
            />
          </div>
          <div className="mb-4 mx-4">
            <button
              type="submit"
              className="w-full px-4 py-3 bg-gray-600 text-white rounded-lg hover-bg-yellow-600 focus:outline-none focus:ring focus-border-blue-500"
              disabled={!newExpiredDate || isSubmitting}
            >
              {isSubmitting ? "Changing..." : "Change"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeExpiredDate;
