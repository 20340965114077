import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "moment";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import { BiCalendar } from "react-icons/bi";
import Axios from "../../../service/Axios";

const BackdateTransactions = () => {
  const [searchedTransId, setSearchedTransId] = useState("");
  const [newDate, setNewDate] = useState(null);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBackdate = async (e) => {
    e.preventDefault();
    if (!newDate) {
      setShowAlert({ type: "error", message: "Please select a new date." });
      return;
    }

    setIsSubmitting(true);

    try {
      const formattedDate = moment(newDate).format("YYYY-MM-DD");

      const response = await Axios.put(
        "/v1/backdate-transaction",
        {
          transId: searchedTransId,
          newDate: formattedDate,
        }
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Transaction backdated successfully.",
        });
        setSearchedTransId("");
        setNewDate(null);
      } else {
        setShowAlert({
          type: "error",
          message: "Backdating failed. Please try again later.",
        });
      }
    } catch (error) {

      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = "No response received from server.";
      } else {
        errorMessage = "Request setup error.";
      }
      setShowAlert({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className='container mx-auto p-4 scale-90'>
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className='bg-white bg-opacity-80 rounded-lg shadow-md p-6 mx-10'>
        <div className='flex'>
          <div className='flex-col justify-center'>
            <h2
              className='text-2xl font-semibold mb-8 mt-6 mx-4'
              style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
              [ Backdate Transaction ]
            </h2>
          </div>
        </div>

        <form
          autoComplete='off'
          onSubmit={handleBackdate}
          className='mt-6 flex flex-col space-y-4'>
          <label
            htmlFor='searchedTransId'
            className='text-s mx-4 text-gray-600 font-semibold'>
            Transaction ID:
          </label>
          <div className='relative mx-4'>
            <input
              autoComplete='off'
              type='text'
              id='searchedTransId'
              value={searchedTransId}
              onChange={(e) => setSearchedTransId(e.target.value)}
              className='w-full py-3 px-4 mb-4 rounded-lg border border-gray-400 bg-white focus:outline-none focus:ring-2 focus:ring-gray-400 text-gray-800 shadow-md'
              placeholder='Enter Transaction ID'
              disabled={isSubmitting}
            />
          </div>

          <label
            htmlFor='newDate'
            className='mx-4 text-s text-gray-600 font-semibold'>
            New Date:
          </label>
          <div className='relative mx-4'>
            <DatePicker
              selected={newDate}
              onChange={(date) => setNewDate(date)}
              className='w-full py-2 px-4 mb-8 rounded-lg border border-gray-400 bg-white focus:outline-none focus:ring-2 focus:ring-gray-400 text-gray-800 shadow-md'
              dateFormat='yyyy-MM-dd'
              withPortal
              showYearDropdown
              yearDropdownItemNumber={10}
              customInput={
                <div className='relative'>
                  <input
                    type='text'
                    name='newDate'
                    value={newDate ? moment(newDate).format("YYYY-MM-DD") : ""}
                    readOnly={isSubmitting}
                    className='w-full px-4 py-1 rounded-lg text-xl focus:outline-none focus:ring-2 focus:ring-gray-400 cursor-pointer pl-10'
                  />
                  <BiCalendar className='icon-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-xl text-gray-400 cursor-pointer' />
                </div>
              }
            />
          </div>

          <div className='relative mx-4'>
            <button
              type='submit'
              className='w-full py-3 mb-10 bg-gray-600 text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-lg shadow-md transition-all duration-300'
              disabled={!newDate || isSubmitting}>
              {isSubmitting ? "Backdating..." : "Backdate"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BackdateTransactions;
