import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from "../../components/Sidebar/sidebar";
import Header from "../../components/Header/Header";
import { useTheme } from "../../context/ThemeContext";

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isTaskBoard =
    window.location.href === "http://localhost:3000/task-board";
  const isTicketLogs = window.location.href === "/";
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  // fetch api auth me if error 401 redirect ke login

  return (
    <div className={`flex ${isDarkMode ? 'bg-gradient-to-t from-cyan-100 via-yellow-50 to-cyan-100' : 'bg-gradient-to-t from-stone-200 via-stone-100 to-stone-200'}`}>
      <Sidebar/>
      <div
        className='flex-1 grow-[3] p-2 relative flex flex-col'
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Header />
        <main className={`p-5 rounded-lg flex-grow shadow-md hover:shadow-lg mx-4 px-4 ${isDarkMode ? 'bg-gradient-to-t from-slate-200 via-slate-100 to-slate-200' : 'bg-gradient-to-b from-gray-100 via-gray-100 to-gray-50'}`}
          style={{
            overflow: "auto",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05) inset",
            backdropFilter: "blur(10px)",
            marginBottom: "16px",
            zoom: "85%",
          }}>
          {children}
        </main>
      </div>
    </div>
  );
}

export default ProtectedRoute;
