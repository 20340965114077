import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaBolt } from "react-icons/fa";
import { setRole, setEmailUser } from "./userSlice";
import Axios from "../../service/Axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("token");
  const dispatch = useDispatch();

  const handleLogin = () => {
    Axios
      .post("/v1/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          const userData = response.data;
          console.log(response.data);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("role", response.data.data.role);
          setNotification({ type: "success", message: "Login successful." });
          dispatch(setRole(userData.data.role));
          dispatch(setEmailUser(userData.data.email));
          setTimeout(() => {
            setNotification(null);
          }, 3000);
          navigate("/");
        }
      })
      .catch((error) => {
        setNotification({
          type: "error",
          message: "Login failed. Please try again.",
        });
        setTimeout(() => {
          setNotification(null); // Clear the notification after a certain time
        }, 3000);
      });
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  return (
    <div className='min-h-screen flex flex-col justify-center items-center bg-gradient-to-br from-blue-300 to-gray-600 relative'>
      <div className='absolute inset-18 bg-white transform -skew-y-8 -rotate-8 rounded-3xl shadow-4xl'></div>
      <div className='scale-75 relative px-10 py-12 bg-white shadow-lg rounded-3xl max-w-6xl mx-auto'>
        <div className='text-center'>
          <div className='flex items-center justify-center'>
            <FaBolt className='h-8 w-8 text-yellow-500 animate-spin-slow' />
            <h1
              className='text-3xl font-bold font-mono text-black ml-2'
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
              TechOps Dashboard
            </h1>
          </div>
        </div>

        <h1 className='text-xl font-light text-center mb-6 mt-6'>Login</h1>
        {notification && (
          <div
            className={`bg-${notification.type}-200 text-${notification.type}-900 p-2 rounded-md mb-4`}>
            {notification.message}
          </div>
        )}
        <div className='divide-y divide-gray-200'>
          <div className='py-6 space-y-4 text-gray-700'>
            <div className='relative'>
              <input
                autoComplete='off'
                id='email'
                name='email'
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='peer placeholder-transparent h-14 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600'
                placeholder='Email'
              />
              <label
                htmlFor='email'
                className='absolute left-0 -top-4 text-gray-600 text-lg peer-placeholder-shown:text-xl peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-4 peer-focus:text-gray-600 peer-focus:text-lg'>
                Email
              </label>
            </div>
            <div className='relative'>
              <input
                autoComplete='off'
                id='password'
                name='password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='peer placeholder-transparent h-14 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600'
                placeholder='Password'
              />
              <label
                htmlFor='password'
                className='absolute left-0 -top-4 text-gray-600 text-lg peer-placeholder-shown:text-xl peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-4 peer-focus:text-gray-600 peer-focus:text-lg'>
                Password
              </label>
            </div>
            <div className='relative'>
              <button
                type='button'
                onClick={handleLogin}
                className='bg-gray-500 hover:bg-yellow-600 text-white rounded-md mt-10 px-4 py-2 text-lg w-full font-bold font-mono'>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
