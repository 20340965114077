export const FEATURE_OPTION_LISTS = [
    { value: "CHANGE NAME", label: "Change Name" },
    { value: "CHANGE EMAIL", label: "Change Email" },
    { value: "CHANGE HOME CLUB", label: "Change Home Club" },
    { value: "CHANGE EXPIRED DATE", label: "Change Expired Date" },
    { value: "CHANGE START DATE", label: "Change Start Date" },
    { value: "CHANGE START DATE PT", label: "Change Start Date PT" },
    { value: "CHANGE PROMO CODE", label: "Change Promo Code" },
    { value: "CHANGE PAYMENT METHOD", label: "Change Payment Method" },
    { value: "CHANGE PACKAGES", label: "Change Packages" },
    { value: "BACKDATE TRANSACTION", label: "Backdate Transaction" },
  ];
  